interface ISbase {
    host: string,
    api: any,
}
export const getBaseHost = function(){
    return window.location.protocol+'//' + window.location.host
}

const host = getBaseHost();
const base:ISbase = {
    host:host,
    api: {
        LedgerAccessUrl:host+"/web-api/stencil-service-api/luckysheetFun/LedgerAccess",
        domainDataPageQueryUrl:host+"/web-api/form-service-api/domain_data/pageQuery",
    },
}

export default base

