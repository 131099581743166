import Cookies from 'js-cookie'
const tokenKey = 'login_access_token'
const userIdKey = 'login_access_userId'
export const getToken = () => Cookies.get(tokenKey)
export const getUserId = () => Cookies.get(userIdKey)
export const ajax = function({url,type,data},callback){
    $.ajax({
        url,
        type,
        async:false,
        data: JSON.stringify(data),
        dataType:'JSON',
        traditional: true,// 如果提交的数据的值有列表，则需要添加此属性
        crossDomain : true, //跨域问题
        contentType: "application/json; charset=utf-8",//请求需要发送的处理数据  编码格式
        beforeSend: function(request) {
            request.setRequestHeader("X-Access-Token", getToken());
            request.setRequestHeader("X-Access-ID", getUserId());
        },
        success: function (success) {
            callback(success)
        },
        error:function(error){
            callback(error)
        }
    })
}