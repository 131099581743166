export const accessType_ACCT = {
    CY:'CY 年初余额',
    JCY:'JCY 年初借方余额',
    DCY:'DCY 年初贷方余额',
    C:'C 期初余额',
    JC:'JC 借方期初余额',
    DC:'DC 贷方期初余额',
    Y:'Y 期末余额',
    JY:'JY 借方期末余额',
    DY:'DY 贷方期末余额',
    JF:'JF 本期借方发生额',
    DF:'DF 本期贷方发生额',
    JL:'JL 本年累计借方发生额',
    DL:'DL 本年累计贷方发生额',
    SY:'SY 损益表本期发生额',
    SL:'SL 损益表本年累计发生额',
    TCY:'TCY 本位币年初余额',
    TJCY:'TJCY 本位币年初借方余额',
    TDCY:'TDCY 本位币年初贷方余额',
    TC:'TC 本位币期初余额',
    TJC:'TJC 本位币借方期初余额',
    TDC:'TDC 本位币贷方期初余额',
    TY:'TY 本位币期末余额',
    TJY:'TJY 本位币借方期末余额',
    TDY:'TDY 本位币贷方期末余额',
    TJF:'TJF 本位币本期借方发生额',
    TDF:'TDF 本位币本期贷方发生额',
    TJL:'TJL 本位币本年累计借方发生额',
    TDL:'TDL 本位币本年累计贷方发生额',
    TSY:'TSY 本位币损益表本期发生额',
    TSL:'TSL 本位币损益表本年累计发生额',
    QC:'QC 期初结存数量',
    QY:'QY 期末结存数量',
    QJF:'QJF 当期收入数量',
    QDF:'QDF 当期发出数量',
    QJL:'QJL 本年累计收入数量',
    QDL:'QDL 本年累计发出数量'
}
export const currency = {
    CNY:'CNY 人民币',
    CUR:'CUR-00000001 新加坡元',
    'CUR-00000002':'CUR-00000002 韩元',
    'CUR-00000003':'CUR-00000003 泰铢',
    'CUR-00000004':'CUR-00000004 越南盾',
    'CUR-00000005':'CUR-00000005 印尼盾',
    'CUR-00000006':'CUR-00000006 马来西亚林吉特',
    'CUR-00000007':'CUR-00000007 澳元',
    'CUR-00000008':'CUR-00000008 加元',
    EUR:'EUR 欧元',
    GBP:'GBP 英镑',
    HKD:'HKD 港币',
    JPY:'JPY 日元',
    USD:'USD 美元',
}
export const accessType_ACCTCASH = {
    Z:'Z 折合本位币金额',
    y:'Y 原币金额'
}
